@import '../../../../assets/styles/sass/variables/variable';

  .sidebar_container {
    width: 96px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: $primary-default;
    padding: 30px 10px;
    z-index: 1001;
    overflow-x: hidden;
    overflow-y: auto;
      &::-webkit-scrollbar {
        width: 1px !important;
        height: 1px !important;
        background-color: transparent !important;
        z-index: 10;
      }
      &::-webkit-scrollbar-thumb {
        width: 1px !important;
        height: 1px !important;
        background-color: rgba(255,255,255, .5) !important;
        z-index: 10;
      }

    .sidebar_header{
      padding-bottom: 6px;
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: $primary-default;
      .brand{
        height: 46px;
        width: 100%;
        max-width: 230px;
        transition: 2s width ease-in-out;
        &.expandedLogo{
          padding-left: 20px;
        }
      }
    }

    .search_wrapper{
      padding: 6px 12px;
    }

    .top_content{
      padding: 16px 0;
      flex-grow: 1;
      // overflow-y: auto;
      // &::-webkit-scrollbar {
      //   width: 1px !important;
      //   height: 1px !important;
      //   background-color: transparent !important;
      //   z-index: 10;
      // }
      // &::-webkit-scrollbar-thumb {
      //   width: 1px !important;
      //   height: 1px !important;
      //   background-color: rgba(255,255,255, .5) !important;
      //   z-index: 10;
      // }
    }

    .links_container{
      padding: 12px 0;
    }

    .side_link{
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      gap: 10px;
      transition: all .25s ease-in-out;
      svg{min-width: 2.1rem; min-height: 2.1rem;}
      .link_text{
        font-size: 14px;
        color: $white;
        margin-bottom: 0;
        white-space: nowrap;
        span{
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          min-width: 26px;
          border-radius: 20px;
          padding: 0 3px;
          background-color: $secondary-300;
          color: $grey-900;
        }
      }
      .dot{
        position: absolute;
        width: 10px;
        height: 10px;
        background-color: $secondary-300;
        top: 4px;
        left: 38px;
        border-radius: 50%;
      }
      &.active {
        background-color: $primary-700;
      }
      &:hover{
        background-color: $primary-700;
      }
      &.user_profile{
        padding-left: 10px;
        .profile_content{
          padding-left: 16px;
          p{white-space: nowrap};
          .name {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }

    .hide_if_not_expanded{
      display: none;
      // padding-left: 6px;
      flex-grow: 1;
    }
    &.expanded{
      width: 310px;
      transition: .2s width ease-in-out;
      .hide_if_not_expanded{
        display: unset;
      }
      .profile_content{
        p{
          text-overflow: ellipsis;
        }
      }
    }
  }