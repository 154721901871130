@import "../../../assets/styles/sass/main.scss";
.btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 6px;
  font-size: 12px;
  border: none;
  font-weight: 500;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.primary {
    background-color: $primary-default;
    font-weight: 500;
    color: $white;
    &:hover {
      background-color: darken($primary-default, 8%);
    }
  }
  &.danger {
    background-color: $warning-default;
    font-weight: 500;
    color: $white;
    &:hover {
      background-color: darken($primary-default, 8%);
    }
  }
  &.light {
    background-color: $bg-700;
  }
  &.bordered {
    background-color: transparent;
    font-weight: 500;
    border: 1px solid $primary-default;
    color: $primary-700;
  }

  &.linkBtn {
    background-color: transparent;
    color: $primary-700;
    font-weight: 400;
    &:hover {
      background-color: transparent;
      color: darken($primary-700, 20%);
    }
  }
  &.default_link {
    background-color: transparent;
    color: $link-default;
  }

  &.onlyIcon {
    padding: em(12) !important;
  }
  &.sm {
    padding: 0 !important;
  }
  &.md {
    padding: 8px !important;
  }
  &.rounded {
    border-radius: 24px;
  }
  &.icon_right {
    flex-direction: row-reverse;
  }
}
