@import '../../../assets/styles/sass/variables/variable';
.profilepic_container{
    padding: 12px;
    position: relative;
    &.no_padding{
        padding: 0;
    }

    .profilepic{
        border-radius: 50%;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        margin: auto;
        border: 2px solid $primary-700;
        background-color: $secondary-100;
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 1 / 1;

        &.xs{
            width: 36px;
            height: 36px;
            border: none; 
        }

        &.sm{
            width: 56px;
            height: 56px;
            border: none;
        }
        &.md{
            max-width: 120px;
            max-height: 120px;
        }
        &.lg{
            max-width: 200px;
            max-height: 200px;
        }
        &.no_border{
            border: none;
        }

        .placeholder{
            width: 220px;
            height: 220px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            h6{
                color: $primary-default;
                font-size: 16px;
            }
        }
    }

    .upload_btn{
        position: absolute;
        background-color: $secondary-default;
        bottom: 6px;
        left: 55%;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: none;
    }
}





