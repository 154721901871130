@import '../../../../../assets/styles/sass/variables/variable';
.side_dropdown{
    transition: all .45s ease-in-out;
    .dropdown_btn{
        padding: 10px;
        padding-left: 24px;
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        width: 100%;
        transition: all .25s ease-in-out;
        svg{min-width: 2.1rem; min-height: 2.1rem;}
        &.active {
            background-color: $primary-700;
        }
        &:hover{
            background-color: $primary-700;
        }
        .link_text{
            font-size: 14px;
            color: $white;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }
    .side_link{
        padding: 8px 8px 8px 52px;
        display: flex;
        align-items: center;
        transition: all .45s ease-in-out;
        svg{min-width: 2.1rem; min-height: 2.1rem;}
        .link_text{
          font-size: 14px;
          color: $white;
          margin-bottom: 0;
        }
        &.active {
          background-color: $primary-700;
        }
        &:hover{
          background-color: $primary-700;
        }
    }
    .hide_if_not_expanded{
        display: none;
        padding-left: 6px;
        width: 100%;
    }
    &.expanded{
        .hide_if_not_expanded{
          display: unset;
        }
    }
}